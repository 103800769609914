import { createI18n } from 'vue-i18n'
import { tr } from './tr'
import { en } from './en'

const Languages = {
    tr: tr,
    en: en
}

const i18n = createI18n({
    locale: 'tr',
    legacy: false,
    globalInjection: true,
    silentTranslationWarn: true,
    messages: Languages,
    dateTimeFormats: {
        'tr-TR': {
            short: {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            },
            long: {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                weekday: 'short',
                hour: 'numeric',
                minute: 'numeric'
            }
        }
    },
    numberFormats: {
        en: {
            currency: {
                style: 'currency',
                currency: 'USD',
                symbol: '$'
            }
        },
        tr: {
            currency: {
                style: 'currency',
                currency: 'TRY',
                symbol: '₺'
            }
        }
    }
})

export default i18n
