import {defineAsyncComponent} from 'vue'

export const GlobalComponents = {
    'script-global-blank': defineAsyncComponent(() => import('./BlankComponent.vue')),

    // Helpers
    'script-svg-icon': defineAsyncComponent(() => import('./SvgIconComponent.vue')),
    'script-play-button': defineAsyncComponent(() => import('./PlayButtonComponent.vue')),
    'script-rate-view': defineAsyncComponent(() => import('./RateComponent.vue')),
    'script-text-to-speech': defineAsyncComponent(() => import('./TextToSpeechComponent.vue')),
    'script-money': defineAsyncComponent(() => import('./MoneyComponent.vue')),
}
