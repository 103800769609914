// Core
import {createApp} from 'vue/dist/vue.esm-bundler';
import {createPinia} from 'pinia'

// App
import ElementPlus from 'element-plus';
import { vMaska } from 'maska/vue'

// Misc
import Lodash from 'lodash'
import './utils/RegisterServiceWorker'
import i18n from './langs'

// Components
import {GlobalComponents} from './components'
import {AppComponents} from './app'

const app = createApp(null, {});

Lodash.forEach(GlobalComponents, (value, index) => {
    app.component(index, value)
})

Lodash.forEach(AppComponents, (value, index) => {
    app.component(index, value)
})

// Directives
app.directive('maska', vMaska)

// Modules
app.use(createPinia())
app.use(i18n)
app.use(ElementPlus);

// Mount
app.mount('#Bootstrap');
